<template>
<div>   
<XModal :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
<div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
           <div class='appHead' :style="getAppStyle()">
             <div style='display: inline-flex; float: left; font-weight: bold; padding-top: 2pt; margin-right: 15pt;'>
             
             </div>
            
              <WindowCloseBN @click="close"/>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft' style='text-align: right;'>
            	Issues for {{channel}}@{{date}} 
                </div>	

            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" id='main-body'>
	  
	      <div  >
          <slot name="body">
           
			<button :style='getAppStyle()' @click="openDetailP()" class="button myButton">Open {{tr("Daily Rundown")}} {{channel}} {{date}}</button>
			&nbsp;
			<button :style='getAppStyle()' @click="run('copyRotation')" class="button myButton">Run copy rotation</button>
			&nbsp;
			<button :style='getAppStyle()' @click="run('optimizer')" class="button myButton">Run optimiser</button>
			&nbsp;
			<button :style='getAppStyle()' @click="run('playlist')" class="button myButton">Create Playlist</button>
			&nbsp;
			<button :style='getAppStyle()' @click="run('reload')" class="button myButton"><mdicon size='16' name='reload'/></button>
			<br/>
			<br/>
			
			<div class='transition' style='display: block; height: 10pt; margin: 4pt; padding: 0pt; font-size:10pt;'>
			<div style='display: inline-flex; font-weight: bold; padding-right: 1ex; width: 6em;'>Booked</div> Revenue {{formatNumber(dayStats.revenueHC)}}, Spots {{dayStats.spots}} 
			</div>
			<div class='transition' style='color: #888; display: block; height: 10pt; margin: 4pt; padding: 0pt; font-size:10pt;'>
			<div style='display: inline-flex; font-weight: bold; padding-right: 1ex;  width: 6em;'>Clipboard</div> Revenue {{formatNumber(dayStats.revenueHCLimbo)}}, Spots {{dayStats.spotsLimbo}} 
			</div>
            <div v-if="spots.length"  class='mainContent transition' :style='getTableStyle()'>
             <table>
             <tr>
             	<td class='thead'>Client</td>
             	<td class='thead'>Issue</td>
             	<td class='thead'>Severity</td>
             	<td class='thead' colspan=3>Actions</td>
             </tr>
              <tr v-for="(spot, idx) in spots" :key="'i'+idx" >
                <td class='issueText'>{{spot.clientName}}</td>
                <td class='issueText'>{{issues[idx].name}}</td>
                <td class='issueText' :style="severityStyle(showSeverity[idx])+';padding-left: 2pt !important;'">{{severities.find(s=>s.id===issues[idx].severityId).name}} ({{showSeverity[idx]}})</td>
                
				<td>
				<button :style='getAppStyle()' v-if="spot.placementId" @click="$refs.editor.open('Placement', spot.placementId)" class="button btn">{{tr("Placement")}}</button>
				</td><td>
				<button :style='getAppStyle()' v-if="spot.copyId" @click="$refs.editor.open('Copy', spot.copyId)" class="button btn">{{tr("Copy")}}</button>
				</td><td>
				<button :style='getAppStyle()' v-if="spot.placementId" @click="openBookMan(spot)" class="button btn">{{tr("Book Manager")}}</button>
				</td>
			  </tr>
			  </table>
            </div>  
            <div v-else>
            no issues detected
            </div> 
            
	      </slot>
	      </div>
	     
	      </div>
	      </div>
			<GFWEOpenEditor  ref='editor' @update='reload'></GFWEOpenEditor>
			
	  </XModal>




</div>   
</template>
<script>
import { formatNumber} from '@/variables.js';
import { tr } from '@/translate.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { setGoBack } from '@/breadCrumb.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
export default {
  props: {
    name: {
      type: String,
      default: 'ShowIssues'
    },
  	 height: {
      type: Number,
      default: 75
    },
    severities: Array,
    spots: Array,
    dayStats: Object,
    issues: Array,
    showSeverity: Array
  },
 components : {
	 WindowCloseBN
  },
  data () {
    return {
      width: 66,
      
      channel: "",
      channelId: 0,
      date: "",
      tr, formatNumber, getAppStyle
    }
  },

 methods: {
 severityStyle(s)
 {
 	if ( s === 'blocker')
 	{
 		return 'background-color: #fbb; color: #000;';
 	}
 },
 contextmenu(event,spot){
        event.preventDefault();
       
        this.$refs.xmenu.open(event, {spot: spot } )
	},
 openBookMan( spot ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	this.$router.replace('/bookMan?toOpen=Placement&list=true&id='+ spot.placementId);
       }, 
openDetailP()
{
	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	this.$router.replace('detailPlanning?stationId='+this.channelId+'&day='+ this.date);
}, 
run(what) {
	this.$emit(what, this.channelId, this.date)
},
getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 104px)";
     },	
 close() {  this.$modal.hide(this.name); this.$emit("close") },
 open( channel, channelId, date) 
 {
    this.date = date;
    this.channel = channel;
    this.channelId = channelId;
 	this.$modal.show(this.name);
 }
 }
}
</script>
<style lang="scss" scoped>
.time { 
    
    font-size: 7pt; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
    border-left: 1px solid #ccc; 
    border-bottom: 1px solid #ccc; 
    background-color: #eef; 
    opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s;
 }
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 0; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 8pt;
}
.issueText {
	font-size: 9pt;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
	font-size: 8pt;
}
.thead {
	font-size: 9pt;
	color: #fff;
	background-color: #444;
	font-weight: bold;
}
.myButton {
	//width: 18%;
    padding: 2px 2px 2px 2px !important;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    font-size: 11px !important;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 2pt;
}
</style>