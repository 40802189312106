<template>
<div>
<div style='display: inline-flex; padding: 0; width: 83px; height: 14px;'>

	<progress-bar style='margin-top: -2px; margin-left: 0px;'
      :options="(percentage<=100) ? PBOptions: PBOptionsRed"
      :value="Math.round(100*percentage)/100"
      />
</div>
</div>
</template>
<script>

export default {
  props: {
  	percentage: { type: Number, default: 0.0 }
  },
 components : {
    
  },
  data () {
    return {
      PBOptions: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 10,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#6d6',
		    backgroundColor: '#eee'
		  },
		  layout: {
		    height: 11,
		    width: 80,
		    verticalTextAlign: 80,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsRed: {
		  text: {
			color: '#eee',
			shadowEnable: false,
		    fontSize: 10,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#c66',
		    backgroundColor: '#eee'
		  },
		  layout: {
		    height: 11,
		    width: 80,
		    verticalTextAlign: 80,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 }
    }
  }
 }
</script>
<style lang="scss" scoped>

</style>